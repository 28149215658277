import { Link } from 'gatsby';
import React from 'react';
import Layout from '../components/layout/layout';

const Blog = () => {
    return (
        <Layout
            title="Blog - WrapTown - profesjonalne oklejanie samochodów"
            desc="Wpisy o oklejaniu samochodów. Dowiesz się jaką folię wybrać, jak dbać o oklejone auto czy jak ochronić lakier przed rysami."
            url="https://wraptown.pl/blog"
            type="website"
        >
            <main className="px-4 max-w-7xl mx-auto pt-20 pb-20 lg:px-6">
                <div className="mb-10">
                    <p>
                        <Link to="/">Home</Link> / Blog
                    </p>
                </div>
                <div>
                    <h1 className="text-4xl  mb-6 font-extrabold text-black xl:text-5xl leading-tight xl:leading-snug">
                        Blog - WrapTown
                    </h1>
                    <p>Jeszcze nic tu nie ma...</p>
                </div>
            </main>
        </Layout>
    );
};

export default Blog;
